/**
 * Wraps jQuery ajax calls to provide pre/post ajax callbacks
 *
 */

LE = LE || {};

LE.Ajax = (function (_, $, document) {

    var beforeSubscribers = [];
    var successSubscribers = [];
    var errorSubscribers = [];
    var stopSubscribers = [];

    var init = function()
    {
        beforeSubscribers = [];
        successSubscribers = [];
        errorSubscribers = [];
        stopSubscribers = [];
    }

    var addBeforeSubscriber = function(sub)
    {
        beforeSubscribers.push(sub);
    }

    var addSuccessSubscriber = function(sub)
    {
        successSubscribers.push(sub);
    }

    var addErrorSubscriber = function(sub)
    {
        errorSubscribers.push(sub);
    }

    var addStopSubscriber = function(sub)
    {
        stopSubscribers.push(sub);
    }

    var ajaxSend = function(event, xhr, options)
    {
        console.log("ajaxSend");

        _.each(beforeSubscribers, function(sub){
           sub(event, xhr, options);
        });
    };

    var ajaxSuccess = function(event, xhr, options){

        console.log("ajaxSuccess");

        _.each(successSubscribers, function(sub){
            sub(event, xhr, options);
        });
    }

    var ajaxError = function(event, xhr, options){

        console.log("ajaxError");

        _.each(errorSubscribers, function(sub){
            sub(event, xhr, options);
        });
    }

    var ajaxStop = function()
    {
        console.log("ajaxStop");

        _.each(stopSubscribers, function(sub){
           sub();
        });
    }

    var onReadyHandler = function(){

        // register with jQuery's global callbacks
        $(document).ajaxError(ajaxError);
        $(document).ajaxSend(ajaxSend);
        $(document).ajaxSuccess(ajaxSuccess);


        console.log('Configured $.ajax event handlers');
    };

    var onReady = {
        name : "LE.Ajax",
        handler : onReadyHandler
    };

    return {
        init : init,
        onReady : onReady,
        addBeforeSubscriber: addBeforeSubscriber,
        addSuccessSubscriber: addSuccessSubscriber,
        addErrorSubscriber: addErrorSubscriber,
        addStopSubscriber: addStopSubscriber
    };

})(_, jQuery, document);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Ajax;
}