/**
 * Provides a list and method for initialising the LE namespace modules in dependency order
 *
 */

if (typeof global !== 'undefined')
{
    global.LE = {};
}
else
{
    LE = LE || {};
}


LE.Modules = (function (_) {

    var modules = [
        "Console",
        "Bootstrap",
        "Ajax",
        "Url",
        //"LiveType"
    ];

    var init = function(){

        _.each(modules, function(module){

            global.LE[module] = require(__dirname + "/" + module.toLowerCase());

        });

    };

    return {
        name : "LE",
        modules : modules,
        init : init
    }

})(_);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Modules;

}