var Timetable = Timetable || {};

Timetable.TutorTimetableComposer = (function (LE, App, _, $, document, Handlebars) {

    var instance = function (options) {

        // Start with Tutor
        // Group by Days
        // Group by School
        // List all times block per school
        // Student dropdown per school id (per instrument?)
        /// Collectio nf students
        // Show alert if student removed from a time block

        var students = [],
            allStudentsCollection = [],
            instruments = [],
            $container = $('.panel-body');

        var init = function () {
            instruments = JSON.parse(options.instruments);

            getStudents();
            //parseExistingEntries();
        };

        var getStudents = function () {
            $.ajax({
                url: options.payload_url,
                dataType: 'json',
                success: function (response) {
                    _.each(response.students, function (element, index, list) {
                        _.each(element.children, function (student, studentIndex, studentList) {
                            allStudentsCollection.push({
                                id: student.id,
                                name: student.text,
                                text: student.text + ' (' + student.year + ' ' + student.class + ')',
                                class: student.class,
                                year: student.year,
                                school_id: element._id,
                                school_name: element.text,
                                instruments: student.instruments
                            })
                        });
                    });
                },
                complete: function (data) {
                    parseExistingEntries();
                }
            });
        }

        var parseExistingEntries = function () {
            // Check if edit
            // Ajax get entries
            // Then parse json returned

            $.ajax({
                url: options.get_url,
                dataType: 'json',
                success: function (response) {
                    var html = [];
                    var tabpanels = [];

                    var _days = [
                        '',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                    ];

                    students = response.students;

                    var _i = 0;
                    _.each(response.data.day, function (element, index, list) {
                        var tabpanel = [];

                        var _class = _i == 0 ? 'active' : '';
                        html.push('<li role="presentation" class="' + _class + '">');
                        html.push('<a href="#day' + _days[index] + '" aria-controls="day' + _days[index] + '" role="tab" data-toggle="tab">' + _days[index] + '</a>');
                        html.push('</li>');

                        tabpanel.push('<div role="tabpanel" class="tab-pane ' + _class + '" id="day' + _days[index] + '" data-day="' + index + '">');

                        _.each(element.school, function (eElement, schoolId, eList) {
                            var schoolname = eElement.name;

                            tabpanel.push('<br><div data-school=' + schoolId + '>');
                            tabpanel.push('<p class="text-left text-upper">' + schoolname + '</p>');
                            tabpanel.push('<div class="row" data-timerow><div class="col-sm-12"><table class="timetable-table table table-striped table-bordered table-responsive"><thead><tr><th></th><th style="width:300px">Students</th><th style="width:110px">Start</th><th style="width:110px">End</th><th>Room</th><th>Instrument</th></tr></thead><tbody class="o-sortable"></tbody></table></div></div>');
                            tabpanel.push('</div>');
                        });

                        tabpanel.push('</div>');

                        $('.panel-body')
                            .find('#output .tab-content')
                            .append(tabpanel.join(''));

                        _i++;

                        _.each(element.school, function (eElement, schoolId, eList) {
                            _.each(eElement.entries, function (entryElement, entryIndex, entryList) {
                                generateTimeBlockForSchool('#day' + _days[index], schoolId, entryElement);

                                var eStudents = [];
                                _.each(entryElement.students, function (e) {
                                    eStudents.push('' + e);
                                });

                                $('[data-timeentry][data-entry-id="' + entryElement.id + '"]')
                                    .find('td[data-students] select')
                                    .val(eStudents)
                                    .trigger('change');
                            });
                        });
                    });

                    $('[data-daytabs]').html(html.join(''));
                }
            })

        };

        var getStudentsFromSchoolId = function (schoolId) {
            var collect = _.filter(allStudentsCollection, function (obj) {
                return obj.school_id == parseInt(schoolId) && $.inArray(obj.id, students) !== -1
            });

            return collect;
        }

        var generateTimeBlockForSchool = function (element, schoolId, entry) {
            var html,
                template = LE.templates['tutortimetable_time'];

            html = template({
                start: entry.start,
                end: entry.end,
                room: entry.room,
                instrument: entry.instrument,
                instruments: instruments,
                students: getStudentsFromSchoolId(schoolId),
                entryId: entry.id
            });

            var $tableBody = $(document).find(element)
                .find('[data-school=' + schoolId + ']')
                .find('.row[data-timerow] table tbody');

            $tableBody.append(html);

            $tableBody
                .find('[data-entry-id="' + entry.id + '"]')
                .find('td[data-instrument] select')
                .val(entry.instrument);

            initSelect2();

            sortable('.o-sortable', 'reload');

            onSortUpdate();
        }

        var onSortUpdate = function () {
            $(document).find('.o-sortable').off('sortupdate').on('sortupdate', function (e) {
                // Get current element
                var $this = $(e.detail.item);
                // Get current element times
                var times = {
                    start: $this.find('td[data-start] input').val(),
                    end: $this.find('td[data-end] input').val(),
                };

                // Create newTimes variable
                var $newTimes;

                var filtered;
                var $filtered;

                // If we moved the element up (smaller index)
                if (e.detail.destination.index < e.detail.origin.index) {
                    // console.log('moved up');
                    // get elements greater than / equal to new index
                    // get elements less than old index
                    filtered = e.detail.destination.items.filter(function (element, index) {
                        return index > e.detail.destination.index && index <= e.detail.origin.index;
                    });

                    // jQuery object
                    $filtered = $(filtered);
                    // foreach $filtered
                    $filtered.each(function (index) {
                        var $that = $(this);
                        var $next;

                        // If the element is first index (the one pushed down) then set the element times to the current filtered
                        if (index === 0) {
                            $newTimes = {
                                start: $that.find('td[data-start] input').val(),
                                end: $that.find('td[data-end] input').val()
                            }
                        }

                        // If the next index is the length,
                        // then the next times will be the original element times we moved
                        if (index + 1 === $filtered.length) {
                            $next = {
                                start: times.start,
                                end: times.end
                            }
                        } else {
                            // Else shift the times to the next in the array
                            $next = {
                                start: $that.next().find('td[data-start] input').val(),
                                end: $that.next().find('td[data-end] input').val()
                            };
                        }
                        // Set the current $(filtered) element new times
                        $that.find('td[data-start] input').val($next.start);
                        $that.find('td[data-end] input').val($next.end);
                    });
                }

                // If we moved the element down (larger index)
                if (e.detail.destination.index > e.detail.origin.index) {
                    // console.log('moved down');
                    // get elements less than / equal to new index
                    // get elements greater than old index
                    filtered = e.detail.destination.items.filter(function (element, index) {
                        return index < e.detail.destination.index && index >= e.detail.origin.index;
                    });

                    $filtered = $(filtered);

                    // jQuery object
                    $filtered = $(filtered);
                    // foreach $filtered
                    var $prevTimes = [];

                    $filtered.each(function (index) {
                        var $that = $(this);
                        var $prev;
                        // Set the previous times
                        $prevTimes[index] = {
                            start: $that.find('td[data-start] input').val(),
                            end: $that.find('td[data-end] input').val()
                        };

                        // If first element pushed up, set current filtered to element
                        if (index === 0) {
                            $prev = {
                                start: times.start,
                                end: times.end,
                            }
                        } else {
                            // The times are the previous index times
                            $prev = $prevTimes[index - 1];
                        }

                        if (index + 1 === $filtered.length) {
                            $newTimes = {
                                start: $that.find('td[data-start] input').val(),
                                end: $that.find('td[data-end] input').val()
                            };
                        }
                        // Set the current $(filtered) element new times
                        $that.find('td[data-start] input').val($prev.start);
                        $that.find('td[data-end] input').val($prev.end);
                    });

                }

                // Set the new times for the current element
                $this.find('td[data-start] input').val($newTimes.start);
                $this.find('td[data-end] input').val($newTimes.end);
            });
        }

        var initSelect2 = function () {
            try {
                $(document).find('select.select2-hidden-accessible').select2('destroy');
            } catch (e) {

            } finally {
                $(document).find('select').select2();
            }
        };

        // Filter students by selected instrument
        var filterStudentsOnInstrument = function ($element) {

            // $element is the select element
            // Get nearest row, then get student dropdown, then filter by instrument id
            var selectedInstrument = parseInt($element.val());

            var $studentSelect = $element
                .closest('tr[data-timeentry]')
                .find('td[data-students] select');

            var schoolId = $element.closest('[data-school]').data('school');

            var _students = App.Common.deepClone(getStudentsFromSchoolId(schoolId));

            var filteredStudents = _.filter(_students, function (obj) {
                return $.inArray(selectedInstrument, obj.instruments) !== -1;
            });

            $studentSelect.select2('destroy').empty()
                .select2({ data: filteredStudents });
        };

        var findStudentsNotSelected = function () {
            var selectedStudents = [];

            var $selectedStudents = $(document).find('td[data-students] select');

            _.each($selectedStudents, function (element, index, list) {
                selectedStudents.push($(element).val());
            });

            selectedStudents = _.compact(_.flatten(selectedStudents)).map(function (elem) {
                return parseInt(elem, 10);
            });

            var notSelectedStudents = _.difference(students, selectedStudents);

            if (notSelectedStudents.length) {

                var filteredStudents = _.filter(allStudentsCollection, function (obj) {
                    return $.inArray(obj.id, notSelectedStudents) !== -1;
                });

                filteredStudents = _.groupBy(filteredStudents, 'school_name');

                var message = '<br>';
                _.each(filteredStudents, function (el, inde, li) {
                    message += '<br><strong>' + inde + ':</strong>' + '<br>';

                    _.each(el, function (ell, iin, lli) {
                        message += '- ' + ell.text + '<br>';
                    });
                });

                $.notify({
                    // options
                    icon: 'icon-failure',
                    title: 'The following students are missing from the timetable:',
                    message: message
                }, {
                    type: 'danger'
                });

                return false;
            }

            return true;
        }

        // Save data in similar format as returned by school generatePDF
        var submit = function () {
            var collection = [];

            var allow = findStudentsNotSelected();

            if (!allow) {
                $container.find('input[type=submit]').val('Save changes');
                return;
            }

            var $dayBlocks = $('#output .tab-pane');

            _.each($dayBlocks, function (element, index, list) {
                var $element = $(element);

                var $schoolEntries = $element.find('[data-school]');

                _.each($schoolEntries, function (schoolElement, schoolIndex, schoolList) {
                    var $schoolElement = $(schoolElement);

                    var object = {
                        school: parseInt($schoolElement.data('school')),
                        day: parseInt($element.data('day')),
                        entries: []
                    }

                    var $timeEntries = $schoolElement.find('tr[data-timeentry]');
                    _.each($timeEntries, function (element1, index1, list1) {
                        var $entry = $(element1);

                        var entryObj = {
                            students: $entry.find('td[data-students] select').val(),
                            start: $entry.find('td[data-start] input').val(),
                            end: $entry.find('td[data-end] input').val(),
                            room: $entry.find('td[data-room] input').val(),
                            instrument: parseInt($entry.find('td[data-instrument] select').val()),
                            id: parseInt($entry.data('entry-id'))
                        };

                        object.entries.push(entryObj);
                    });

                    collection.push(object);
                });
            });

            $container.find('form input[name=timetable_data]').val(JSON.stringify(collection));
            $container.find('form').submit();
        };

        $container.on('change', 'td[data-instrument] select', function (e) {
            filterStudentsOnInstrument($(this));
        });

        $container.on('click', 'input[type=submit]', function (e) {
            $container.find('input[type=submit]').val('Processing...');
            e.preventDefault();
            submit();
        });

        init();

        sortable('.o-sortable', {
            forcePlaceholderSize: true,
            //acceptFrom: '.o-sortable'
        });

        return {

        }
    };

    var create = function (options) {
        return new instance(options);
    };

    return {
        create: create
    };

})(LE, App, _, jQuery, document, Handlebars);

if (typeof module === "object" && typeof module.exports === "object") {
    module.exports = Timetable.TutorTimetableComposer;
}