
LE = LE || {};

LE.Mustache = (function (window, _) {

    /**
     * Renders a template contained in a HTML element into an arbitrary container.
     *
     * @param source string selector or jQuery Element
     * @param container string selector or jQuery Element
     * @param templateData obj
     */
    var renderScriptTemplate = function(source, container, templateData)
    {
        var $source = _.isString(source) ? $(source) : source;

        var template = $source.html().trim();

        templateData = _.isObject(templateData) ? templateData : {};

        var output = Mustache.render(template, templateData);

        var $container = _.isString(container) ? $(container) : container;

        $container.html(output);
    }

    return {
        renderScriptTemplate : renderScriptTemplate
    };

})(window, _);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Mustache;
}