/**
 * Pimped out DataTable with the following default features:
 *
 * 1) Calls global App.Loading.onProcessing when processing
 * 2) Uses custom search and show count select boxes defined by elements tagged with data-datatable-search
 *    and data-datatable-show-count respectively
 * 3) Sets up BatchCheckboxSelect (if first column contains checkboxes)
 *
 */
var LE = LE || {};

(function ( $, _ ) {

    //alert(App.Loading);

    LE.CreateDataTable = function(options)
    {
        if (!options.tableId)
            throw "Missing tableId";

        var $tableWrap = $(options.tableId);
        var isFixedheader = options.fixedHeader;
        var clearBatch = _.has(options, 'clearBatch') ? options.clearBatch : true;

        var colSortBy;
        var serverSide = !options.serverSide ? true : options.serverSide;
        var responsive = !options.dataTable.responsive ? true : options.dataTable.responsive;
        var selectFilters = !options.filters ? true : options.filters;

        if(options.colSortBy) {
            colSortBy = options.colSortBy.split(":");
        } else {
            colSortBy = ['1', 'asc'];
        }

        colSortBy[0] = parseInt(colSortBy[0]);

        var $table = $tableWrap.find('table[data-datatable]');

        var batchSelect;

        var tableDom = '<"table-top row"<"table-top-controls col-sm-6"><"table-top-pages col-sm-6"ip>><"table-responsive datatable_scroll"rt><"table-bottom row"<"table-bottom-controls col-sm-6"><"table-bottom-pages col-sm-6"ip><"clear">>';

        /**
         * Move batch actions and show count select into table-top-controls and table-bottom-controls
         * when they have been created by the DataTable plugin.
         *
         * Setup handlers for any batch actions
         *
         * @param settings
         * @param json
         */
        var onTableInit = function(tableId) {

            var batchId = '#' + tableId + '-batch';
            var showCountId = '#' + tableId + '-show-count';

            // TODO: replace fragile selectors

            $(batchId).appendTo($(batchId).siblings('.dataTables_wrapper').find('.table-top-controls'));

            $(showCountId).appendTo($('#' + tableId + '_wrapper .table-bottom-controls'));

            if (options.batchActions)
            {
                _.each(options.batchActions, function(actionFunc, actionName){

                    var actionButtonId = '#batch-action-' + actionName;

                    var actionButton = $tableWrap.find(actionButtonId);

                    actionButton.on('click', function(e){
                        e.preventDefault();
                        var selectedIds = batchSelect.getSelectedIds();

                        actionFunc(selectedIds);

                        return false;
                    });
                });
            }
        };

        var onTableDraw = function(tableId){

            var $checkboxSelect = $('#' + tableId + ' [data-batch-checkbox-select]');
            $checkboxSelect.data('batch-checkbox-select', 'all');

            $checkboxSelect.prop('checked', false);

            $tableWrap.find('#' + tableId + ' .table-batch-actions').hide();

            batchSelect = $('#' + tableId + ' [data-batch-checkbox-select]').BatchCheckboxSelect({
                //onProcessing: App.Loading.onProcessing,
                onChange: function($checkbox, numSelected){
                    onCheckboxChange(tableId, $checkbox, numSelected)
                }
            });
        };

        var onCheckboxChange = function(tableId, $checkbox, numSelected){
            // select table row

            var $behindCheckbox = $('.dataTables_scrollBody').find('input[name='+$checkbox.attr('name')+']');

            if ($checkbox.prop("checked"))
            {
                $checkbox.parents("tr:first").toggleClass('selected', true);
                $behindCheckbox.prop('checked', true);
                $behindCheckbox.parents("tr:first").toggleClass('selected', true);
            }
            else
            {
                $checkbox.parents("tr:first").toggleClass('selected', false);
                $behindCheckbox.prop('checked', false);
                $behindCheckbox.parents("tr:first").toggleClass('selected', false);
            }

            // enable/disable batch action button(s)

            var batchId = '#' + tableId + '-batch';

            if (numSelected > 0)
            {
                $(batchId).show();
            }
            else
            {
                $(batchId).hide();
            }

            if (options.onSelectChange)
            {
                options.onSelectChange($checkbox, numSelected, tableId);
            }
        };

        /*
            TODO: Select the selected column data on table redraw
            TODO: Order days by Mon-Fri, not alphabetical order
            @see https://datatables.net/examples/api/multi_filter_select.html
         */
        var addFilterColumnFooter = function(table) {
            table.api().columns().every( function () {
                var column = this;
                var showFilter = true;

                if(Array.isArray(selectFilters)) {
                    var colIndex = column.index();

                    var filter = _.find(selectFilters, function (index) {
                            return index == colIndex;
                        }
                    );

                    if(_.isUndefined(filter)) {
                        showFilter = false;
                    }
                }

                if( column.dataSrc() != null && showFilter) {
                    var select = $('<select><option value="">Show all</option></select>')
                        .appendTo($(column.footer()).empty())
                        .on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            column
                                .search(val ? '^' + val + '$' : '', true, false)
                                .draw();
                        });

                    // TODO: sort() fails Mon-Fri
                    column.data().unique().sort().each(function (d, j) {
                        var val = $('<div/>').html(d).text();

                        if(column.search() === '^'+d+'$'){
                            select.append( '<option value="'+val+'" selected="selected">'+val+'</option>' )
                        } else {
                            select.append('<option value="' + val + '">' + val + '</option>')
                        }
                    });
                }
            } );
        };

        var pagelengthId = options.tableId + ' #table-show-count';

        // defaults
        var pageLength;

        options = $.extend(true,
            {
                max_client_records : 250,
                searchId: "#user-datatable-search",
                pageLengthId: "#table-show-count",
                //onProcessing: App.Loading.onProcessing,
                dataTable: {
                    /*
                        NOTE: For column sorting, wont work with pagination or anything because the values
                        don't get updated and the search selected value doesn't stay

                        TODO: Keep pagination, this makes the tables really slow
                     */
                    responsive: false,
                    scrollY: '50vh',
                    scrollX: true,
                    scrollCollapse: true,
                    paging: false,
                    deferRender: true,

                    //bDestroy: true,
                    order: [[ colSortBy[0], colSortBy[1] ]],
                    //sDom: tableDom,
                    //initComplete: onTableInit,
                    drawCallback: function (settings){
                        if(clearBatch)
                            clearBatchActions();

                        onTableDraw(settings.sTableId);
                        // addFilterColumnFooter(this);
                    },

                    "stateLoadParams": function (settings, data) {

                        $(pagelengthId).val(data.length).trigger('change');
                    },

                    // Kill off the last searched
                    "stateSaveParams": function(settings,data)
                    {
                        data.search.search = null;

                        _.each(data.columns, function(collection) {
                            collection.search.search = null;
                        });
                    },
                    // fnInitComplete: function (settings, json) {
                    //
                    //     $('[data-toggle="tooltip"]').tooltip({html: true});
                    //
                    //     onTableInit(settings.sTableId);
                    //
                    //     if(isFixedheader==true) {
                    //         // Append the new FixedHeader to the table-responsive div
                    //         new $.fn.dataTable.FixedHeader($dataTable, {
                    //             alwaysCloneTop: true
                    //         });
                    //
                    //         $fixedHeaderWidth();
                    //     }
                    // },
                    initComplete: function (settings, json) {

                        onTableInit(settings.sTableId);

                        addFilterColumnFooter(this);

                        var table = this;
                        table.api().columns.adjust().draw();
                    },
                    serverSide: serverSide,
                    tabIndex: -1,
                    oLanguage : {
                        sInfo: "_START_ to _END_ of _TOTAL_",
                        sInfoEmpty: "",
                        oPaginate : {
                            sPrevious : "",
                            sNext : ""
                        }
                    }
                }
            }, options);

        //console.log(options);

        $dataTable = $table.DataTableControls({
            search: $tableWrap.find('input[data-datatable-search]'),
            pageLengthId: pagelengthId,
            //onProcessing: App.Loading.onProcessing,
            dataTable: options.dataTable
        });

        $dataTables = {};

        $dataTables[options.tableId] = $dataTable;

        var clearBatchActions = function()
        {
            $('.table-batch-actions').css('display', 'none');
        };

        var $fixedHeaderWidth = function() {
            var dataTableWidth = $('.datatable_scroll').width();
            var smallWidth = dataTableWidth - 1;

            // Set the width of the dataTable (override table width !important)
            var element = document.getElementsByClassName('dataTable');
            element[0].setAttribute('style', 'width:'+smallWidth+'px !important');
            element[0].setAttribute('width', smallWidth);
            element[0].style.setProperty( 'width', smallWidth+'px', 'important' );

            $(".fixedHeader").css( "width", dataTableWidth);
            $(".fixedHeader").css( "max-width", dataTableWidth);
        };

        if(isFixedheader==true) {

            // TODO: Do we need this here as we run it on fnInitComplete
            $fixedHeaderWidth();

            $(window).resize(function() {
                $fixedHeaderWidth();
            });

            // Scroll the fixedHeader with the table#users-table
            $(".datatable_scroll").scroll(function() {
                $(".fixedHeader").scrollLeft($(".datatable_scroll").scrollLeft());
            });
        }

        $tableWrap.find('input[type=search]')
            .unbind()
            .keyup(_.debounce(function(e) {
                //console.log('input search');
                $dataTable.search($(this).val()).draw();
            } , 500));

        return {
            getSelectedIds : function()
            {
                return batchSelect.getSelectedIds();

            },
            // TODO: Find a better way to do this for scrollHeaders
            batchClick: function()
            {
                return batchSelect.selectClick()
            },
            dataTable : $dataTable,
            fixedHeaderWidth : function()
            {
                return $fixedHeaderWidth();
            },
            reload : function()
            {
                return $dataTable.ajax.reload();
            },
            reloadInstance : function(id)
            {
                $instance = $(id).DataTable();

                //console.log($instance);

                return $instance.ajax.reload();
            }
        }
    };

})(jQuery, _);