var TimetableModule = TimetableModule || {};

TimetableModule.Timetable = (function (LE, App, _, $, document, Handlebars) {

    var Timetable = function (options) {
        var
            payloadUrl = options.payload_url,
            userRole = options.user_role,
            filters = [],
            $widget = options.container;

        var init = function () {
            // Register handlers etc
            getData({ day: 1 });

            registerOnClickHandlers();
        };

        // Private functions

        var registerOnClickHandlers = function () {
            $widget.on('click', 'a[data-day]', function (e) {
                // Clean out html
                // Preload day html
                App.Loading.toggleLoader(true);

                $widget.find('.panel-body')
                    .empty()
                    .text('Loading Timetable... Please wait');

                var day = $(this).data('day');
                getData({ day: day });
            })
        };

        var getData = function (data) {

            $.ajax({
                url: payloadUrl,
                type: 'get',
                data: data,
                success: function (response) {
                    console.log(response);

                    if (response.data && _.size(response.data) > 0) {
                        buildContext(response.data);
                    } else {
                        $widget
                            .find('.panel-body')
                            .empty()
                            .text('Nothing to show for this day');
                    }
                }
            })
        };

        var buildContext = function (data) {
            var context = {
                name: []
            };

            _.each(data, function (value, key, list) {
                _.each(value, function (entries, account, list) {

                    var nameObj = { name: account, timeblock: [] };

                    _.each(entries, function (entry, k2, list) {
                        var timeblockObj = {
                            start: entry.start,
                            end: entry.end,
                            label: entry.label,
                            room: entry.room,
                            instrument: entry.instrument ? entry.instrument : entry.display_name,
                            students: []
                        };

                        _.each(entry.student_accounts, function (student, k3, list) {
                            var studentObj = {
                                name: student.user.name,
                                class: student.class,
                                year: student.year
                            };

                            timeblockObj.students.push(studentObj);
                        });
                        nameObj.timeblock.push(timeblockObj);

                    });

                    // Push our name block on
                    context.name.push(nameObj);
                });
            });

            generateTemplate(context);
        };

        var generateTemplate = function (context) {
            var html,
                template = LE.templates['timetable_day'];

            html = template(context);

            $widget.find('.panel-body').empty().append(html);
        };

        init();

        return {

        }
    };

    var create = function (options) {
        return new Timetable(options);
    };

    return {
        create: create
    };

})(LE, App, _, jQuery, document, Handlebars);

if (typeof module === "object" && typeof module.exports === "object") {
    module.exports = TimetableModule.Timetable;
}