/**
 */

var LE = LE || {};

LE.Datepicker = (function (_, $, document) {

    var Datepicker = function(options)
    {
        var $container = $(options.container);
        var format = options.datepicker ? (options.datepicker.format ? options.datepicker.format : 'dd-mm-yyyy') : 'dd-mm-yyyy';
        var $datepicker;

        var formatServerToClient = function() {
            var value = $container.val();

            if( !_.isEmpty(value)) {
                value = moment(value, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']).format('DD-MM-YYYY');

                $container.val(value);
            }
        };

        var formatClientToServer = function() {

            var date = $container.val();

            if(_.isEmpty(date))
                return '';

            return moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
        };

        var altField = function() {
            var value = $container.val();
            var name = $container.attr('name');

            if(!_.isUndefined(value) && !_.isEmpty(value)) {
                value = value.split('-');
                value = value[2] + '-' + value[1] + '-' + value[0];


                var $formattedValue = moment(value).format('YYYY-MM-DD');

                var html = '<input name="'+name+'" type="hidden" value="'+$formattedValue+'">';
                $(html).appendTo($container.parent());

                $container.removeAttr('name');
            }

        };

        var updateDate = function(date)
        {
            date = moment(date).format('DD-MM-YYYY');

            $container.datepicker('update', date);
        };

        // Extend the options
        options.datepicker = $.extend(true, {
            autoclose: true,
            format: format,
            todayBtn: false,
            weekStart: 1,
            zIndexOffset: 150

        }, options.datepicker);

        formatServerToClient();

        $datepicker = $container.datepicker(options.datepicker);

        // Todo
        $('form').submit(function(event) {
            altField();
        });

        return {
            datepicker : $datepicker,
            format: formatClientToServer,
            updateDate: updateDate
        }

    };

    var create = function(options)
    {
        return new Datepicker(options);
    };

    return {
        create : create
    }

})(_, jQuery, document);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Datepicker;
}