/**
 * Wrapper around window.console to toggle output based on a config.
 *
 */

LE = LE || {};

LE.Console = (function (window, _) {

    var noOp = function(){};

    var stubConsole = function()
    {


        var funcs = ["log", "debug", "info", "warn", "error",
                     "assert", "dir", "dirxml", "group", "groupEnd", "time",
                     "timeEnd", "count", "trace", "profile", "profileEnd"];

        _.each(funcs, function(func){
            window.console[func] = noOp;
        });
    };

    var init = function(enabled)
    {
        if (!window.console || !enabled)
        {
            stubConsole();
        }
    };

    // public
    var console = {
        name : "Console",
        init : init
    };

    return console;

})(window, _);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Console;
}