/**
 *
 */

var LE = LE || {};

LE.DatepickerRange = (function (_, $, document) {

    var DatepickerRange = function(options)
    {
        var datepickerDefaults = {
            weekStart:1,
            todayBtn: false
        };

        if (options.weekendOnly)
        {
            datepickerDefaults.daysOfWeekDisabled = "1,2,3,4,5,6";
        }

        var datepickerOptions = $.extend(true, datepickerDefaults, options.datepicker);

        var datepickerFrom = createDatepicker(options.fromContainer, datepickerOptions);

        var datepickerTo = createDatepicker(options.toContainer, datepickerOptions);

        // datepicker to >= from

        datepickerFrom.datepicker.on('changeDate', function(e) {

            var value = $(this).val();

            var fromDate = moment(value, "DD-MM-YYYY");
            var toDate = moment(datepickerTo.datepicker.val(), "DD-MM-YYYY");

            if (fromDate.isAfter(toDate, 'day'))
            {
                datepickerTo.datepicker.val(value);
            }

        });

        datepickerTo.datepicker.on('changeDate', function(e) {

            var value = $(this).val();

            var toDate = moment(value, "DD-MM-YYYY");
            var fromDate = moment(datepickerFrom.datepicker.val(), "DD-MM-YYYY");

            if (fromDate.isAfter(toDate, 'day'))
            {
                datepickerFrom.datepicker.val(value);
            }

        });

        var fromDateServer = function()
        {
            return datepickerFrom.format();
        };

        var toDateServer = function()
        {
            return datepickerTo.format();
        };

        return {
            datepickerFrom : datepickerFrom,
            datepickerTo : datepickerTo,
            fromDateServer : fromDateServer,
            toDateServer : toDateServer
        };
    };

    var createDatepicker = function(container, datepickerOptions)
    {
        return LE.Datepicker.create({
            container: container,
            datepicker: datepickerOptions
        });
    };

    var create = function(options)
    {
        return new DatepickerRange(options);
    };

    return {
        create : create
    }

})(_, jQuery, document);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.DatepickerRange;
}