var CalendarModule = CalendarModule || {};

CalendarModule.Widget = (function(LE, App, _, $, document) {

    var instance = function(options) {
        var
            $modal = options.modal,
            dateTimeFormat = options.dateTimeFormat,
            $calendar = options.calendar,
            routes = options.routes,
            $startDateTimePicker,
            $endDateTimePicker;

        var emptyModalForm = function($modal) {
            $modal.find('button#eventDelete')
                .hide();

            $modal.find('input,textarea')
                .val('');

            $modal.find('input[type=checkbox]')
                .prop('selected', false)
                .prop('checked', false);

            $modal.find('input[name="id"]')
                .remove();
        };

        var initDatetimepicker = function() {
            var options = {
                format: dateTimeFormat,
                sideBySide: true
            };

            $startDateTimePicker = $modal.find('input[name="start"]')
                .datetimepicker(options);

            $endDateTimePicker = $modal.find('input[name="end"]')
                .datetimepicker(options);
        };

        $calendar = $('#calendar')
            .fullCalendar({
                events: routes.read_events,
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'month,agendaWeek,agendaDay,listMonth'
                },
                navLinks: true,
                selectable: options.editable,
                eventLimit: false,
                editable: options.editable,
                weekends: false,
                eventStartEditable: false,
                weekNumbers: false,
                themeSystem: 'bootstrap3',
                select: function(start, end) {
                    if(options.editable) {
                        // TODO: Check the difference between start and end, if 24 hours, disable end time and set allDay as checked
                        emptyModalForm($modal);

                        $modal.find('input[name="start"]')
                            .val(moment(start)
                                .format(dateTimeFormat));

                        $modal.find('input[name="end"]')
                            .val(moment(end)
                                .format(dateTimeFormat));

                        $modal.find('.modal-title')
                            .text('Add new Event');

                        $modal.find('button#eventAction')
                            .text('Create')
                            .data('action', 'create');

                        $modal.modal('show');
                    }
                },
                eventClick: function(calEvent, jsEvent, view) {
                    if(options.editable) {
                        emptyModalForm($modal);

                        $modal.find('input[name="title"]')
                            .val(calEvent.title);

                        $modal.find('textarea[name="description"]')
                            .val(calEvent.description);

                        if (calEvent.allDay === true) {
                            $modal.find('input[type=checkbox]')
                                .prop('checked', true);
                        }

                        $modal.find('input[name="start"]')
                            .val(calEvent.start.format(dateTimeFormat));

                        if (!_.isNull(calEvent.end)) {
                            $modal.find('input[name="end"]')
                                .val(calEvent.end.format(dateTimeFormat));
                        }

                        $modal.find('.modal-title')
                            .text('Edit event');

                        $modal.find('button#eventAction')
                            .text('Update')
                            .data('action', 'update');

                        $modal.find('button#eventDelete')
                            .show();

                        $modal.find('form')
                            .append('<input type="hidden" name="id" value="' + calEvent.id + '" />');

                        $modal.modal('show');
                    }
                }
            });

        initDatetimepicker();

        $startDateTimePicker.on('dp.change', function(e) {
            var startDate = $startDateTimePicker.val();
            // if startDate is after endDate, then set the val(startDate) on $endDateTimePicker
            $endDateTimePicker.data("DateTimePicker")
                .minDate(startDate);
        });

        var createEvent = function(data) {
            var success = function(response) {
                var newEvent = {
                    id: response.data.id,
                    title: response.data.title,
                    start: response.data.startTime,
                    end: response.data.endTime,
                    allDay: response.data.allDay,
                    description: response.data.description,
                    color: response.data.color
                };

                $calendar.fullCalendar('renderEvent', newEvent);

                $modal.modal('hide');
            };
            $.ajax({
                url: routes.create_event,
                type: 'POST',
                data: data,
                success: success
            })
        };
        var updateEvent = function(data) {
            var success = function(response) {
                var clientEvent = $calendar.fullCalendar('clientEvents', response.data.id);

                var event = clientEvent[0];
                event.title = response.data.title;
                event.description = response.data.description;
                event.start = response.data.startTime;
                event.end = response.data.endTime;
                event.allDay = response.data.allDay;
                event.color = response.data.color;

                $calendar.fullCalendar('updateEvent', event);

                $modal.modal('hide');
            };
            $.ajax({
                url: routes.update_event,
                type: 'POST',
                data: data,
                success: success
            })
        };
        var deleteEvent = function() {
            var id = $modal.find('input[name=id]').val();

            $.ajax({
                url: routes.delete_event,
                type: 'POST',
                data: {
                    _method: 'delete',
                    ids: [id]
                },
                success: function(response) {
                    // Todo: hide confirmation
                    $calendar.fullCalendar('removeEvents', id);
                    $modal.modal('hide');
                }
            })
        };

        // Window resize
        var currentView = 'month';
        $(window).resize(function() {

           if($(window).width() < 997) {
               if(currentView !== 'listMonth') {
                   $calendar.fullCalendar('changeView', 'listMonth');
                   currentView = 'listMonth';
               }
           } else {
               if(currentView !== 'month') {
                   $calendar.fullCalendar('changeView', 'month');
                   currentView = 'month';
               }
           }
        });

        if(options.editable) {
            $modal.on('click', 'button#eventDelete', function (e) {
                e.preventDefault();
                var $this = $(this);

                $modal.find('button#eventDelete')
                    .confirmation('show');
            });

            $modal.find('button#eventDelete')
                .confirmation({
                    onConfirm: function (event) {
                        deleteEvent();
                    }
                });

            $modal.on('click', 'button[type=submit]', function (e) {
                e.preventDefault();

                var $this = $(this);
                var data = $modal.find('form')
                    .serialize();

                if ($this.data('action') == 'create') {
                    createEvent(data);
                } else {
                    updateEvent(data);
                }
            });
        }

        return {
            calendar: $calendar
        }
    };

    var create = function(options)
    {
        return new instance(options);
    };

    return {
        create: create
    };
})(LE, App, _, jQuery, document);

if (typeof module === "object" && typeof module.exports === "object") {
    module.exports = CalendarGlobal.Widget;
}