(function ( LE, $, _ ) {

    /**
     *
     * @param options
     * @constructor
     */
    $.fn.ModalTemplate = function (options) {

        var settings = $.extend({data:{}}, options);

        var $modal = $(this);

        var $modalTemplate = $modal.find('[data-modal-template]');

        if($modalTemplate.length > 0) {
            var source = $modalTemplate.html().trim();
            var $container = $modal.find('[data-modal-content]');
            var template = Handlebars.compile(source);
            var context = _.isObject(settings.data) ? settings.data : {};
            var html = template(context);
            $container.html(html);
        }

        // Attach any handlers to modal button

        if (options.handlers)
        {
            _.each(options.handlers, function(handler) {

                var handlerData = handler.data;

                //console.log('handler data:', handler);

                $button = $('#' + handler.id);

                if (!$button)
                    throw "No button found with id: " + handler.id;

                // remove previous click handlers
                $button.off('click').on('click', function(e){

                    e.preventDefault();

                    return handler.callback($modal, handlerData);
                });
            });
        }

        $modal.modal();
    };

}( LE, jQuery, _ ));