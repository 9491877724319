App = App || {};

App.Common = (function (window, LE, $) {
    var onReadyHandler = function(){

        window.onerror = function (errorMsg, url, lineNumber, column, errorObj)
        {
            console.log('window.onError!');

            App.Loading.toggleLoader(false);
        };

        // Select 2
        $.fn.select2.defaults.set( "theme", "bootstrap" );
        $.fn.select2.defaults.set( "width", "100%" );

        $('select:not(.full-width,.disable-select2,.phpdebugbar-datasets-switcher)').select2({
            placeholder: function(){
                $(this).data('placeholder');
            }
        });

        $('select.full-width:not(.disable-select2,.phpdebugbar-datasets-switcher)').select2({
            placeholder: function(){
                $(this).data('placeholder');
            },
            width: '100%'
        });

        // TODO: Birthday picker module

        // TODO: FileInput module
        $("input[type=file]").fileinput({
            'showUpload': false,
            'showPreview': false,
            'showCancel': false
        });

        /*
        $('video, audio').mediaelementplayer({
            // Do not forget to put a final slash (/)
            pluginPath: 'https://cdnjs.com/libraries/mediaelement/',
            // this will allow the CDN to use Flash without restrictions
            // (by default, this is set as `sameDomain`)
            shimScriptAccess: 'always'
            // more configuration
        });
        */
    };

    // TODO: Make underscore mixin
    var deepClone = function(object) {
        var clone = _.clone(object);

        _.each(clone, function(value, key) {
            if (_.isObject(value)) {
                clone[key] = deepClone(value);
            }
        });

        return clone;
    };

    var compactObject = function(object) {
      var clone = _.clone(object);

      _.each(clone, function(value, key) {
          if(!value) {
              delete clone[key];
          }
      });

      return clone;
    };

    var onReady = {
        name : "App.Common",
        handler : onReadyHandler
    };

    return {
        onReady : onReady,
        deepClone: deepClone,
        compactObject: compactObject
    };

})(window, LE || {}, jQuery);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = App.Common;
}