/**
 *
 *
 */

var Frontend = Frontend || {};

Frontend.AudioPlayer = (function (LE, App, _, $, document) {

    var AudioPlayer = function(options) {
        var player;
        var $element = options.element;

        /**
         * Extend MediaElementPlayer
         * - Add square play button on left
         * - Add track title
         * - Move the scrobble
         */
        var extendME = function() {
            $.extend(mejs.MepDefaults, {
                // Any variable that can be configured by the end user belongs here.
                // Make sure is unique by checking API and Configuration file.
                // Add comments about the nature of each of these variables.
            });

            $.extend(MediaElementPlayer.prototype, {
                // Add square play button on left
                buildleplay: function(player, controls, layers, media) {
                    var $play = layers
                        .append( '<span class="mejs__track-artwork"><img src=""></span>' )
                        .find( '.mejs__track-artwork' );
                },
                // Add track title
                buildlemeta: function(player, controls, layers, media) {
                    var $title;

                    layers.append( '<div class="mejs__track-details"><span class="mejs__track-title"></span></div>' );

                    $title = layers.find( '.mejs__track-title' );

                    player.$node.on('setTrack.cue', function(e, track, player) {
                        track.title = track.title || '';
                        $title.html( track.title );
                    });
                },
                // Move buffer bar

                //
            });
        };

        var setTrack = function(track) {
            // @bug Thee element doesnt reset time to 0 unless we play and pause it
            $element.find('.mejs__playpause-button').switchClass('mejs__pause', 'mejs__play');
            $element.find('.mejs__time-handle').css('left','-3px');
            $element.find('.mejs__time-current').css('width','0%');

            var title;

            title = track.title || '';
            player.$media.attr('title', title);
            player.$node.trigger('setTrack.cue', [track, player]);

            player.setSrc(track.src);
            player.load();
            // @bug Thee element doesnt reset time to 0 unless we play and pause it
            player.play();
            player.pause();

        };
        var play = function() {};
        var pause = function() {};
        var scroll = function() {};
        var updateTrackTime = function() {};

        /**
         * Create mediaelementPlayer instance
         */
        var initPlayer = function() {
            $element.find('audio').mediaelementplayer({
                pluginPath: 'https://cdnjs.com/libraries/mediaelement/',
                shimScriptAccess: 'always',
                features: ['playpause','current','progress','duration',/*'volume',*/
                    'leplay','lemeta'
                ],
                success: function (mediaElement, domObject) {
                    // Move mejs__mediaelement above mejs__controls
                    var $mediaelement = $element.find('.mejs__mediaelement');
                    $mediaelement.prev().insertAfter($mediaelement);

                    // Move mejs__time-rail above mejs__time
                    var $timerrail = $element.find('.mejs__time_container');
                    $timerrail.prev().insertAfter($timerrail);

                    // Make mejs__time_container below mejs__time-rail
                    // and move mejs__time elements inside

                    var $timeContainer = $('<div class="mejs__time-container"></div>');
                    $element.find('.mejs__currenttime-container')
                        .appendTo($timeContainer);

                    $element.find('.mejs__duration-container')
                        .appendTo($timeContainer);

                    $timeContainer.appendTo($element.find('.mejs__controls'));
                }
            });

            var $audio = $element.find('audio');

            var playerId = $audio
                .closest('.mejs__container').attr('id');
            player = mejs.players[playerId];

            $audio.find('.mejs__mediaelement')
                .insertBefore($audio.find('.mejs__controls'));
        };

        // Logic to run on init
        extendME();
        initPlayer();

        return {
            player: player,
            setTrack: setTrack,
            init: initPlayer,
        }
    };

    var create = function(options)
    {
        return new AudioPlayer(options);
    };

    return {
        create: create
    };

})(LE, App, _, jQuery, document);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = Frontend.AudioPlayer;
}