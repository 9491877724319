/**
 * Created by john on 12/05/2016.
 */
LE = LE || {};

LE.Global = (function (_, $) {

    var _args = {};

    return {
        init : function(options) {
            _args = options;

            // some other initialising
        },
        get : function(key) {
            // TODO: underscore to check if key exists
            return _args[key];
        }
    };

})(_, jQuery);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Global;
}