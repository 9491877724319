var LE = LE || {};

LE.Bootstrap = (function ( LE, _, $, toposort ) {

    var debug;
    var readyModules = [];

    var init = function(options)
    {
        options = options || {};

        var defaults = {
            debug : false
        };

        options = _.defaults(options, defaults);

        debug = options.debug;

        if (debug)
        {
            LE.Console.init(true);
        }
        else
        {
            LE.Console.init(false);
        }

        readyModules = [];
    };

    var sortReadyModules = function()
    {
        // Sort modules based on each module's readyRequires dependencies
        if (readyModules.length == 0)
            return;

        // create topological sort graph A requires B array
        var graph = [];

        _.each(readyModules, function(module){

            // does module have dependencies?
            if (_.isObject(module.onReady) && _.isArray(module.onReady.requires) && module.onReady.requires.length > 0)
            {
                // loop through requires
                _.each(module.onReady.requires, function(require){

                    // find module that matches require name
                    var requiredModule = _.find(readyModules, function(searchModule){

                        if (_.isObject(searchModule.onReady) && _.isString(searchModule.onReady.name))
                        {
                            if (require == searchModule.onReady.name)
                            {
                                return true;
                            }
                        }

                        return false;
                    });

                    if (requiredModule)
                    {
                        graph.push([requiredModule, module]);
                    }
                    else
                    {
                        console.error("Required module '" + require + "' has not been registered");
                    }
                });
            }
        });


        var sorted = toposort(graph);

        // add any modules that didn't make it into the sort graph (no requirements)
        _.each(readyModules, function(module){

            if (!_.contains(sorted, module))
                sorted.push(module);

        });

        readyModules = sorted;
    }

    var registerModule = function(module)
    {
        if (typeof module.onReady !== 'object' || typeof module.onReady.handler !== 'function')
            throw "Failed to register module missing onReady function";

        if (_.contains(readyModules, module))
            throw "Attempted to add the same module twice";

        readyModules.push(module);
    }

    // Register modules
    var register = function(modules)
    {
        if (_.isArray(modules))
        {
            _.each(modules, function(module){
               registerModule(module);
            });
        }
        else
        {
            registerModule(modules);
        }
    };

    var getDebug = function()
    {
        return debug;
    }

    var ready = function()
    {
        console.log("App.onReady");

        sortReadyModules();

        _.each(readyModules, function(module){
           module.onReady.handler();
        });
    };

    // jQuery ready function on DOM loaded event
    $(function(){
        ready();
    });

    // public
    var bootstrap = {
        name : "Bootstrap",
        register : register,
        init : init,
        debug : getDebug,
        ready : ready
    };

    return bootstrap;

})(LE, _, jQuery, toposort);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Bootstrap;
}