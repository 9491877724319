/**
 * Wraps jQuery ajax calls to provide pre/post ajax callbacks
 *
 * Add CSRF Token to all ajax requests
 *
 */

var App = App || {};

App.Ajax = (function (_, $, document, window) {

    var csrfToken;
    var redirecting = false;

    var ajaxPrefilter = function( options, originalOptions, jqXHR)
    {
        // if options.data is a FormObject, we'll need to append the CSRF token.
        // Otherwise treat as a string.
        if (typeof options.data === 'object')
        {
            options.data.append("_token", csrfToken);
        }
        else
        {
            var tokenParam = "&_token=" + encodeURIComponent(csrfToken);

            options.data = options.data + tokenParam;
        }
    };

    var ajaxSuccess = function (event, jqXHR, options, data)
    {
      if (_.isObject(data) && data.alert)
      {
          var alert = JSON.parse(data.alert);

          //console.log(data.alert);

          var title = '';
          var icon = '';

          if (alert.type == "success")
          {
              title = 'Success!';
              icon = 'success';
          }
          else if (alert.type == "danger")
          {
              title = 'Oops!';
              icon = 'failure';
          }

          $.notify({
              // options
              icon: 'icon-' + icon,
              //title: title,
              message: alert.message
          },{
              // settings
              type: alert.type
          });
      }
    };

    var ajaxError = function (event, jqXHR, options, data)
    {
        console.log('ajaxError');

        if (jqXHR.status == 401)
        {
            //TODO: skip if we're attempting login
            App.Loading.toggleLoader(true);

            redirecting = true;

            window.location.href = '/logout';

            return true;
        }

        var message = 'There was an error processing your request, please try again later.';

        if (_.isObject(data) && data.alert)
        {
            var alert = JSON.parse(data.alert);

            message = alert.message;
        }

        $.notify({
            // options
            icon: 'icon-failure',
            //title: 'Error!',
            message: message
        },{
            // settings
            type: 'danger'
        });
    };

    var ajaxStop = function(){
        //console.log('STOP');

        if (!redirecting)
            App.Loading.toggleLoader(false);
    };

    var ajaxStart = function(){
        //console.log('START');

        App.Loading.toggleLoader(true);
    };

    var onReadyHandler = function(){

        // look for csrfToken and add ajax prefilter if it exists
        csrfToken = Laravel.csrfToken;

        if (csrfToken)
        {
            $.ajaxPrefilter(ajaxPrefilter);
        }
        else
        {
            throw "No CSRF Token found";
        }

        // add before
        $(document).ajaxSuccess(ajaxSuccess);
        $(document).ajaxError(ajaxError);
        $(document).ajaxStart(ajaxStart);
        $(document).ajaxStop(ajaxStop);

        // add success and error notifications
    };

    var onReady = {
        name : "App.Ajax",
        handler : onReadyHandler
    };

    return {
        onReady : onReady
    };
})(_, jQuery, document, window);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = App.Ajax;
}