/**
 * Provides a list of key codes
 *
 * This is a stub and isn't exhaustive, add as necessary
 *
 */

LE = LE || {};

LE.Keycodes = (function (_) {

    var keycodes = {
        "return" : 13
    }

    return keycodes;

})(_);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Keycodes;
}