App = App || {};

App.DataTable = (function (window, LE, $, _) {

    /**
     * Ajax helper to call a route with a number of ids
     *
     * @param table
     * @param route
     * @param method
     * @param ids
     * @param complete
     */
    var batchAction = function(table, route, method, ids, complete)
    {
        $.ajax({
            url: route,
            type: 'post',
            data: {
                _method: method,
                ids: ids
            },
            success: function (response) {
                table.reload();
            },
            complete: function (error) {

                if (complete)
                    complete(false);
            }
        });
    };

    var renderSelect = function(data, type, raw)
    {
        var identifier = "datatable-check-" + data.id;

        return '<div style="width: 30px;">' +
            '<input type="checkbox" id="' + identifier + '" name="' + identifier + '" value="' + data.id + '"/>' +
            '</div>';
    };

    var renderBool = function(data)
    {
        if (data == true)
            return '<span class="icon-tick table-icon-tick"></span>';
        else
            return '';
    }

    var createRenderEditLink = function(model, showAsText)
    {
        var edit = '/edit';

        var renderLink = function(data, type, full){

            return '<div class="table-cell-link"><a href="/' + model + '/' + full.id + edit +'"">'
                + (showAsText ? 'Edit' : data) +
                '</a></div>';
        };

        return renderLink;
    };

    var createRenderLink = function (prefix, suffix, idField, target, getFilters)
    {
        if (!idField)
            idField = 'id';


        var renderLink = function(data, type, full){

            var el = '<div class="table-cell-link"><a href="/' + prefix + '/' + full[idField];

            if (suffix)
                el += '/' + suffix;

            if(getFilters)
                el+= '?'+getFilters;

            el += '"';
            if(target)
                el +=' target="'+target+'"';

            el += '>' + data + '</a></div>';

            return  el;
        };

        return renderLink;
    };

    var renderDate = function(data)
    {
        if (data)
        {
            var date = moment(data/*, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']*/).format('DD-MM-YYYY');

            return date;
        }

        return '';
    };

    var renderDateTime = function(date)
    {

        if(date) {
            var date = moment(date/*, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']*/).format('DD-MM-YYYY H:mm:ss');

            return date;
        }

        return '';
    };

    /* UPDATED */
    var renderEmpty = function(data, type, full) {
        return '';
    };

    var renderMediaIcon = function(model, srcField, nameField, filenameField, filepathField)
    {
        var renderLink = function(data, type, full)
        {
            var src = full[srcField],
                edit = '/edit',
                editUrl = '/' + model + '/' + full.id + edit,
                name = full[nameField],
                filename = full[filenameField];

            if(src.length == 0) {
                src = '/img/media/';

                if(full['type'] == 1) {
                    src = src + 'video.png'
                }
                if(full['type'] == 2) {
                    src = src + 'audio.png'
                }
                if(full['type'] == 3) {
                    src = src + 'document.png'
                }
            }

            // TODO: Handlebars template?
            var htmlBuilder = [];

            htmlBuilder.push('<strong class="has-media-icon">');
                htmlBuilder.push('<a href="'+editUrl+'">');
                    htmlBuilder.push('<span class="media-icon image-icon">');
                        htmlBuilder.push('<img alt="" class="attachment-60x60 size-60x60" src="' + src + '" width="60">');
                    htmlBuilder.push('</span> '+ name +'');
                htmlBuilder.push('</a>');
            htmlBuilder.push('</strong>');
            htmlBuilder.push('<p class="filename"><span class="sr-only">File name:</span> ' + filename + '</p>');

            return htmlBuilder.join('');
        };

        return renderLink;
    };

    var renderEllipsis = function(data, type, row)
    {
        return type === 'display' && data.length > 10 ?
            data.substr( 0, 40 ) +'…' :
            data;
    };


    return {
        batchAction  : batchAction,
        renderSelect : renderSelect,
        createRenderEditLink : createRenderEditLink,
        renderMediaIcon: renderMediaIcon,
        renderEmpty: renderEmpty,
        renderDate: renderDate,
        renderEllipsis: renderEllipsis,
        //campaignCandidateBatchActions: campaignCandidateBatchActions,
        //fileImport  : fileImport,
        //renderBool : renderBool,
        //createRenderLink : createRenderLink,
        //createRenderIconButtonLink : createRenderIconButtonLink,
        //renderDayName: renderDayName,
        renderDateTime: renderDateTime,
        //renderTimeFormat: renderTimeFormat,
        //renderTime: renderTime,
        //renderBlocks: renderBlocks,
        //createEmployeeRenderLink: createEmployeeRenderLink,
        //createRenderIconPopover: createRenderIconPopover,
        //renderCaret: renderCaret,
    };

})(window, LE || {}, jQuery, _);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = App.DataTable;
}