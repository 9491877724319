/**
 * Features:
 * - On click of lesson, collapsible row of video,audio,resources
 * - On click of video, popup with mediaelementjs with video url
 * - On click of audio, popup with mediaelementjs with cue.js custom player with tracks urls and title
 * - On click of resources, spinny and just link to the resource file
 * @version 0.1
 *
 * TODO: Would be good to have a global list of tracks to filter by, instead of looping through lessonsRepo
 * TODO: Extract each part out into its own module (ie notifications, lessons, reviews, avatar)
 */

var Frontend = Frontend || {};

Frontend.Controller = (function (LE, App, _, $, document, viewport) {

    var lessonsRepo,
        reviewsRepo,
        audioPlayer,
        $notificationModal,
        $avatarModal,
        $parentPinModal,
        $reviewModal,
        notificationUrl,
        avatarUrl;

    var init = function(options) {
        audioPlayer = options.audioPlayer;
        $notificationModal = $('#notificationsModal');
        $avatarModal = $('#avatarModal');
        $parentPinModal = $('#parentPinModal');
        $reviewModal = $('#reviewModal');
        notificationUrl = options.notificationUrl;
        avatarUrl = options.avatarUrl;
        reviewsRepo = options.reviews;

        parseLessons(options.lessons);

        registerHandlers();
    };

    var parseLessons = function(data) {
        var lessons = App.Common.deepClone(data);

        lessons = _.chain(lessons)
            .pluck('lessons')
            .flatten()
            .value();

        lessonsRepo = lessons;
    };

    var getLessonById = function(id) {
        return _.findWhere(lessonsRepo, {id: id});
    };

    var getTrackById = function(item_id) {

        // Get collection of lessons which have the backing track
        var arr = _.filter(lessonsRepo, function(lesson) {
            return _.some(lesson['library']['backing_tracks'], {item_id: item_id});
        });

        // Extract the baccking tracks and then findwhere item_id
        return _.findWhere(
            _.flatten(
                _.pluck(
                    _.flatten(
                        _.pluck(arr, 'library')
                    ), 'backing_tracks'
                )
        ), {item_id: item_id});

    };

    var avatarUpdate = function(data, successCallback) {
        var avatarId;

        data = $.extend(true, {
            //id: avatarId
        }, data);

        $.ajax({
            method: 'post',
            url: avatarUrl,
            data: data,

            success: function(response) {
                successCallback();
            },
            error: function (xhr, status, error) {
                // TODO: How to handle error
                // console.log(xhr.responseText);
            },
            complete: function() {
                // Remove active avatar
            }
        })
    };

    /**
     * If toggle from close-to-open,
     * Check if element has row assigned to it, then slide down row if it does
     * Else create row, slide up visible row and slide down new row
     * If toggle from open-to-close, slide up visible row
     * @param $element
     */
    var toggleLessonRow = function($element) {
        var lesson,
            videoSrc,
            id = $element.data('lesson'),
            isActiveLesson = $element.data('active'),
            isRowCreated = $element.data('sub-row'),
            instrumentId = $element.closest('.instrument-container').data('instrument'),
            $currentInstrumentContainer = $('.instrument-container[data-instrument="'+instrumentId+'"]');

        lesson = getLessonById(id);
        videoSrc = lesson['library']['video']['filepath'];

        if(!isActiveLesson || isActiveLesson !== true) {

            var context, html,
                template = LE.templates['row_collapse'];

            context = {
                lesson_id: id,
                video_filepath: videoSrc,
                resources: lesson['library']['resources'],
                notes: lesson['notes']
            };
            html = template(context);

            if(!isRowCreated) {
                attachLessonRow($element, html, id);
                $element.data('sub-row', true);
            }

            $currentInstrumentContainer
                .find('.row-collapse').slideUp();

            var $instrumentLessons = $currentInstrumentContainer
                .find('.blok-lesson');

            $instrumentLessons.data('active', false);
            $instrumentLessons.removeClass('active');
            $instrumentLessons.find('.toggler i').switchClass('glyphicon-minus', 'glyphicon-plus');

            $('.row-collapse[data-lesson-id="'+id+'"]').slideDown();
            // Slide down and make active
            $element.data('active', true);
            $element.addClass('active');
            $element.find('.toggler i').switchClass('glyphicon-plus', 'glyphicon-minus');

        } else {
            $('.row-collapse[data-lesson-id="'+id+'"]').slideUp();
            $element.data('active', false);
            $element.find('.toggler i').switchClass('glyphicon-minus', 'glyphicon-plus');
            $element.removeClass('active');
        }
    };

    /**
     * Dependant on viewport size, attach row to after the lessons
     * or attach it directly to the lesson
     * @param $element
     * @param $row
     * @param lessonId
     */
    var attachLessonRow = function($element, $row, lessonId) {

        if(viewport.is('<sm')) {
            // Attach row to lesson
            // console.log('attach row to lesson');
            $element.after($row);
        } else {
            // Attach row to end
            // console.log('attach row to end');
            $element.closest('.row').after($row);
        }
    };

    /**
     * Loop through rows and attach to lesson
     */
    var attachLessonRows = function() {
        var $rows = $('.row-collapse');

        $rows.each(function() {
            var $this = $(this),
                lessonId = $this.data('lesson-id'),
                $element = $('.blok-lesson[data-lesson="'+lessonId+'"]');

            attachLessonRow($element, $this, lessonId);
        });
    };

    var registerHandlers = function() {
        var $charFields = $parentPinModal.find('.char-field');

        $charFields.CharFields({submit: function(e) {

            // We get the updated collection
            // after inputs are changed
            $charFields = $parentPinModal.find('.char-field');

            e.preventDefault();

            var val = [];

            $charFields.each(function(index) {
                val.push( $.trim( $(this).val() ) );
            });

            var input = $('<input>')
                .attr('id', 'parentPinInput')
                .attr('type', 'hidden')
                .attr('name', 'parent_pin').val(val.join(''));

            $parentPinModal.find('.modal-body').append(input);

        }, submitElement: $parentPinModal.find('button[data-submit]') });

        var $document = $(document);

        $document.on('click', '.blok-lesson', function(e) {
            var $this = $(this);

            toggleLessonRow($this);
        });

        $document.on('click', '.blok-video', function(e) {
            var $this = $(this);
            openVideoPopup($this, $this.data('video'));
            e.preventDefault();
        });

        $document.on('click', '.blok-audio', function(e) {
            var $this = $(this);
            openAudioPopup($this);
            e.preventDefault();
        });

        // TODO: move out to audio-player.js
        $document.find('#cueAudioPlayer').on('click', 'li.cue-track', function(e) {
            var $this = $(this);

            var track = getTrackById($this.data('track'));

            audioPlayer.setTrack({
                src: track.filepath,
                title: track.title,
            });

            gtag('event', 'audio_play', {
                'title' : track.title,
                src: track.filepath,
            });

            audioPlayer.player.play();
        });

        $document.on('click', '.blok-rotate .front', function(e) {
            rotateBlok($(this));
        });

        $document.on('click', '.blok-rotate .rotate-back', function(e) {
            rotateBlok($(this));
        });

        // TODO: Review data-term on click, get review
        // TODO: How to handle multiple reviews in term by different tutors?
        $document.on('click', '.profile-reviews .circle[data-term]', function(e) {
            var $this = $(this);
            showReview($this);
            e.preventDefault();
        });

        $document.on('click', '.profile-section .avatar', function(e) {
            $avatarModal.modal('show');
        });

        $avatarModal.on('click', '.img-picker', function(e) {
            var $this = $(this),
                id = $this.data('id'),
                $imgPickerElements = $avatarModal.find('.img-picker'),
                $profileAvatar = $('.avatar.img-circle');

            $imgPickerElements.removeClass('active');
            $this.addClass('active');

            avatarUpdate({avatar_id: id}, function() {
                $profileAvatar.attr('src', $this.find('img').attr('src'));
                $avatarModal.modal('hide');
            });
        });

        $notificationModal.find('button[data-action]').on('click', function(e) {
            var $this = $(this),
                notificationId = $this.parents().eq(1).data('id');

            if($this.data('action') == 'confirm-pin') {
                // Show pin modal with data-id
                $parentPinModal.data('notification-id', notificationId);
                $parentPinModal.modal('show');
            } else {
                // Mark as read
                submitNotification(notificationId, null);
            }
        });

        /**
         * On submit of pin, concat input fields
         * and then submit notification with data
         * and modal to close on success
         *
         * throw alert if pin invalid
         */
        $parentPinModal.find('button#submit').on('click', function(e) {
            var $this = $(this);
            var data = {};
            var notificationId = $parentPinModal.data('notification-id');

            data.parent_pin = $parentPinModal.find('#parentPinInput').val();

            // TODO: Validate parent_pin and then send ajax request

            submitNotification(notificationId, data, $parentPinModal);
        });

        /**
         * On modal hidden event, remove parentPinInput
         * and empty char-field input
         */
        $parentPinModal.on('hidden.bs.modal', function (e) {
            $parentPinModal.find('#parentPinInput').remove();
            $parentPinModal.find('.char-field').val('');
        });

        /**
         * On resize, snap lessonCollapse row to
         * lesson or to container row
         */
        $(window).resize(
            viewport.changed(function() {
                attachLessonRows();
            }, 20)
        );
    };

    var showReview = function($element) {
        var termGroup = $element.data('term');
        var reviews = reviewsRepo[termGroup];
        var review = reviews[0];
        var term = review['term'];

        $reviewModal.find('.modal-title').text(term['term_label'] + ' ' + term['year']);
        $reviewModal.find('.review-value input').prop('checked', false);
        $reviewModal.find('.review-text').text('').css('display','none');

        $reviewModal.find('p[data-notes]').text(review['notes']);

        _.each(review['values'], function(element, index, list) {

            var $container = $reviewModal.find('.review-field-container[data-id='+element.review_field_id+"]");

            $container.find('.review-text').text(element.text).fadeIn();

            var $radioButton = $container
                .find('.review-value input[data-value="'+ element.value +'"]');

            $radioButton.prop("checked", true);
        });

        $reviewModal.modal('show');
    };

    var submitNotification = function(notificationId, data, $modal) {
        data = $.extend(true, {
            id: notificationId
        }, data);

        $.ajax({
            method: 'post',
            url: notificationUrl,
            data: data,

            success: function(response) {
                // console.log(response);

                if($modal) {
                    $modal.modal('hide');
                }

                updateNotificationStatus(data.id);
            },
            error: function (xhr, status, error) {
                // TODO: How to handle error
                // console.log(xhr.responseText);
            },
            complete: function() {
                $parentPinModal.find('#parentPinInput').remove();
                $parentPinModal.find('.char-field').val('');
            }
        })
    };

    // Update notification status
    var updateNotificationStatus = function(notificationId) {
        var $notificationBlock = $notificationModal.find('.notification-block[data-id="'+notificationId+'"]');
        var $circle = $('.profile-notifications').find('.circle:first');

        $notificationBlock.find('.notification-status').html('<span class="status color-green">' +
            '<i class="glyphicon glyphicon-ok"></i>' +
            '</span>');

        var value = parseInt($circle.text());
        value--;

        $circle.text(value);
    };

    var rotateBlok = function($element) {
        $element
            .closest('.blok-rotate-container')
            .toggleClass('hover');
    };

    var openVideoPopup = function($element, src) {

        var playerId = $('#videoPopup').find('.mejs__container').attr('id');
        var player = mejs.players[playerId];

        $.magnificPopup.open({
            key: 'video-lesson',
            mainClass: 'lesson-video-popover',

            items: {
                src: '#videoPopup',
                type: 'inline'
            },
            callbacks: {
                beforeOpen: function() {
                    player.setSrc(src);
                    player.load();
                },
                open: function() {
                    player.play();

                    gtag('event', 'video_play', {
                        'video_src' : src
                    });
                },
                close: function() {
                    player.setSrc('');
                    player.load();
                    player.pause();
                }
            }
        });
    };

    /**
     * TODO: Move player logic to audio-player.js
     * Get lesson backing tracks,
     * Populate track listing
     * Set initial track on audio player
     * @param $element
     */
    var openAudioPopup = function($element) {
        var lessonId,
            lesson,
            playerId,
            player,
            $popup,
            backingTracks;

        $popup = $('#audioPopup');
        lessonId = $element.closest('.row').data('lesson-id');
        lesson = getLessonById(lessonId);
        backingTracks = lesson['library']['backing_tracks'];

        var track = {};
        if(_.size(backingTracks) > 0) {
            track = _.first(backingTracks);
        }

        if(_.size(track)) {

            var context, html,
                source = $("#audio-player-tpl").html(),
                template = LE.templates['audio_player'];

            context = {
                tracks: backingTracks
            };

            html = template(context);

            $popup.find('ol.cue-tracks').empty().append(html);

            $.magnificPopup.open({
                key: 'audio-lesson',
                mainClass: 'lesson-audio-popover',

                items: {
                    src: '#audioPopup',
                    type: 'inline'
                },
                callbacks: {
                    beforeOpen: function() {
                        console.log(track.filepath);

                        audioPlayer.setTrack({
                            src: track.filepath,
                            title: track.title,
                        });
                    },
                    close: function() {
                        audioPlayer.setTrack({
                            src: '',
                            title: '',
                        });
                    }
                }
            });
        }

    };

    return {
        init: init
    };

})(LE, App, _, jQuery, document, ResponsiveBootstrapToolkit);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = Frontend.Controller;
}