/**
 * Helpers for dealing with URLs
 */

LE = LE || {};

LE.Str = (function (window, _) {

    // Get URL without query
    var slugify = function(str)
    {
        var replace = '-';
        var str = this.toString().tidy().standardize().replace(/[\s\.]+/g,replace).toLowerCase().replace(new RegExp('[^a-z0-9'+replace+']','g'),replace).replace(new RegExp(replace+'+','g'),replace);
        if( str.charAt(str.length-1) == replace ) str = str.substring(0,str.length-1);
        return str;
    }

    var pluralise = function(singular, plural, count)
    {
        if (count == 1 || count == -1)
            return singular;
        else
            return plural;
    };

    var limit = function(str, length)
    {
        var output;
        if(str.length > length)
        {
            var shortStr = str.substring(0, length);

            output = '<span title="'+str+'">'+shortStr+'...</span>';
        } else {
            output =  '<span>'+str+'</span>';
        }

        return output;

    };

    // Todo: rounding issues
    var formatMoney = function(value, decimal)
    {
        decimal = decimal ? (_.isNumber(decimal) ? parseInt(decimal) : 1) : 0;

        return '£'+parseFloat(value).toFixed(decimal).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    function capitalize(s)
    {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    // public
    var uri = {
        name : "Uri",
        slugify : slugify,
        pluralise : pluralise,
        limit: limit,
        formatMoney: formatMoney,
        capitalize: capitalize
    };

    return uri;

})(window, _);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Str;
}