var LE = LE || {};

/**
 * @summary Handles ajax submission of a form widget
 *
 * Long.
 *
 * TODO: Make this target all forms with class ajax-submit instead of having to initialise it
 *
 * @type {{create}}
 */
LE.AjaxSubmitWidget = (function (App, _, $, document) {

    var AjaxSubmitWidget = function(options) {
        var $alert = options.alert, // The alert element
            $form = options.form, // The form element
            formData; // Form data serialised

        options = $.extend(true, {
            url: $form.attr('action'),
            method: $form.attr('method') || 'POST',
        }, options);

        var aSubmit = function(e) {
            e.preventDefault();

            //formData = $form.serialize();
            formData = new FormData(document.getElementById($form.attr('id')));
            //var formData = new FormData($form);
            // console.log(formData);

            $.ajax({
                url: options.url,
                data: formData,
                method: options.method,
                contentType: false,
                processData: false,
                enctype: options.enctype,
                headers: {
                    Accept : "application/json; charset=utf-8",
                    //"Content-Type": "application/json; charset=utf-8"
                },

                success: function(response) {
                    $form.find(':input, textarea').not(':checkbox, :submit').val('');

                    $form.find('select').each(function () {
                        $(this).val(null).trigger("change");
                    });

                    $form.find('input:checkbox').attr('checked',false);
                    $form.find('input:checkbox').prop('checked', false);
                }
            });
        };

        var registerEvents = function() {

            $form.submit(aSubmit);
        };

        registerEvents();

        return {

        }
    };

    var create = function(options)
    {
        return new AjaxSubmitWidget(options);
    };

    return {
        create: create
    };

})(App, _, jQuery, document);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.AjaxSubmitWidget;
}