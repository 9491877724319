/**
 * Helpers for dealing with URLs
 */

LE = LE || {};

LE.Url = (function (window, _) {

    // Get URL without query
    var getUrlPart = function(url)
    {
        var queryIndex = url.indexOf('?');

        if (queryIndex == -1)
            return url;

        return url.substring(0, queryIndex);
    }

    var getUrlQuery = function(url)
    {
        var queryIndex = url.indexOf('?');

        if (queryIndex == -1)
            return '';

        // check we don't just have a terminating '?'
        if (queryIndex + 1 >= url.length)
        {
            return '';
        }

        var queryStr = url.substring(queryIndex);

        return queryStr;
    }

    var addQueryParamToUrl = function (url, key, value)
    {
        var queryString = getUrlQuery(url);

        if (queryString == '')
        {
            queryString = '?' + [encodeURI(key), encodeURI(value)].join('=');
        }
        else
        {
            queryString = addQueryParam(queryString, key, value);
        }

        return getUrlPart(url) + queryString;
    }

    /**
     * Adds a key value param to a query string. If the key exists, its value is replaced.
     */
    var addQueryParam = function(query, key, value)
    {
        key = encodeURI(key);

        value = encodeURI(value);

        var kvp = query.substr(1).split('&');

        var i = kvp.length;
        var x;

        while (i--)
        {
            x = kvp[i].split('=');

            if (x[0] == key)
            {
                x[1] = value;
                kvp[i] = x.join('=');
                break;
            }
        }

        if (i < 0)
        {
            kvp[kvp.length] = [key,value].join('=');
        }


        return '?' + kvp.join('&');
    }

    // public
    var uri = {
        name : "Uri",
        getUrlPart : getUrlPart,
        getUrlQuery : getUrlQuery,
        addQueryParam : addQueryParam,
        addQueryParamToUrl : addQueryParamToUrl
    };

    return uri;

})(window, _);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = LE.Url;
}