(function($) {

    /**
     * @param options
     * @constructor
     */
    $.fn.CharFields = function(options) {

        var $charFields = $(this),
            $submit = options.submitElement,
            submitCallback = options.submit;

        $charFields.val('');
        //$charFields.first().focus();

        init();

        $charFields = $(this);

        function init() {
            $.each($charFields, function(index) {
                var $this = $(this);

                var $cloned = $this.clone();
                $cloned.attr('type', 'password');

                if( _.isUndefined( $this.css('-webkit-text-security') ) ) {
                    $this.replaceWith($cloned);
                    // $charFields[index] = $cloned;
                }
            });
        }

        /**
         * Only allow 0-9, tab, backspace
         */
        $charFields.keypress(function(e){
            var _key = e.which || e.keyCode;

            return (_key >= 48 && 57 >= _key) || // 0-9
            (_key === 9) || // tab
            (_key === 8) // backspace
                ? void 0 :
                ( e.preventDefault(), !1 )
        });

        function prev($t){
            var n = $t.parents().eq(1).prevAll('.field-wrap:first');
            n.length > 0 && n.children().children()[0].focus();
        }

        function next($t){
            var n = $t.parents().eq(1).nextAll('.field-wrap:first');
            n.length > 0 ? n.children().children()[0].focus() : $t.select();
        }

        $charFields.on('keydown', function(eventData, handler){
            var _key = eventData.which || eventData.keyCode;

            if( 8 === _key) { // If backspace
                "" === $(this).val() && prev($(this)); // Go to previous if value is empty
            }
            else if (_key >= 37 && 41 >= _key) { // Arrow keys
                return _key === 37 ?  // Left-arrow
                    prev($(this)) : // Go to previous, else
                    _key === 39 && next($(this)), // right-arrow and go to next
                    ( eventData.preventDefault(), !1 );
            }
        });

        $charFields.on('input', function(eventData){
            var $this = $(this);
            "" !== $this.val() && next($this)
        });

        $charFields.on('focus', function(eventData) {
            $(this).select();
        });

        $submit.on('click', submitCallback);
    }
}(jQuery));