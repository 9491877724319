App = App || {};

App.Loading = (function (LE, $) {

    // global
    var toggleLoader = function(show, loaderId)
    {
        loaderId = loaderId || '#loading-spinner-container';

        if (show)
        {
            $(loaderId).show();
        }
        else
        {
            setTimeout(function() {
                $(loaderId).hide();
            }, 500);
        }
    }

    var onProcessing = function(isProcessing)
    {
        toggleLoader(isProcessing);
    }

    var onReadyHandler = function(){

        $('[data-spinner]').each(function(){

            var spinnerId = $(this).data('spinner-id');

            if (!spinnerId)
                spinnerId = '#loading-spinner-container';

            $(this).click(function(){
                toggleLoader(true, spinnerId);
            });
        });
    };

    var onReady = {
        name : "App.Loading",
        handler : onReadyHandler
    };

    return {
        onReady : onReady,
        onProcessing : onProcessing,
        toggleLoader : toggleLoader
    };
})(LE || {}, jQuery);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = App.Loading;
}