/**
 * Batch checkbox select/deselect jQuery plugin
 *
 * Actual selection starts after a short delay to allow any onProcessing functions to update
 * the DOM.
 *
 * Example:
 *
 * <a href="#" data-batch-checkbox="check-user" data-batch-checkbox-select="all">SELECT ALL</a>
 * <a href="#" data-batch-checkbox="check-user" data-batch-checkbox-select="none">SELECT NONE</a>
 *
 * '<input type="checkbox" id="check-user-1" name="check-user-1" value="check-user-1">';
 * '<input type="checkbox" id="check-user-2" name="check-user-2" value="check-user-2">';
 *
 * $('[data-batch-checkbox-select]').BatchCheckboxSelect();
 *
 * options:
 *
 * onProcessing : callback to be fired before and after select/deselect completes (useful for disabling UI
 *                whilst setting large number of checkboxes)
 *
 * onChange : callback when any select box has changed func($el, numSelected)
 *
 * functions: getSelectedIds() returns an array of any selected ids or an empty array if no inputs are checked
 *
 */
(function ( $ ) {

    $.fn.BatchCheckboxSelect = function( options ) {

        var $input = $(this);

        var preDelay = 50;

        var settings = $.extend({}, options);

        var numSelected = 0;

        var getCheckboxSelector = function($selectInput){

            var target = $selectInput.data('batch-checkbox');

            var targetSelector = "";

            // TODO: Fix for multiple datatables, remove after refactor when different targets are used
            try
            {
                var id = $selectInput.selector.split(" ", 1);

                targetSelector = id[0] + " input[id^='" + target + "']";
            }
            catch (e)
            {
                targetSelector = "input[id^='" + target + "']";
            }

            return targetSelector;
        };

        // clear checkboxes
        var targetSelector = getCheckboxSelector($input);

        $(targetSelector).each(function(checkIndex){
            $(this).prop("checked", false);
        });

        // add handler to all checkboxes only if we're interested in change events
        if (settings.onChange)
        {
            // var targetSelector = getCheckboxSelector($(this));

            $(targetSelector).each(function(checkIndex){
                $(this).change(function(){

                    if ($(this).prop("checked"))
                        numSelected++;
                    else
                        numSelected--;

                    numSelected = numSelected > 0 ? numSelected : 0;

                    settings.onChange($(this), numSelected);
                });
            });
        }

        var selectClick = function() {

            if (settings.onProcessing)
            {
                settings.onProcessing(true);
            }

            var $selectInput = $(this);

            var targetSelector = getCheckboxSelector($input);

            window.setTimeout(function(){

                var select = $selectInput.data('batch-checkbox-select');

                if(_.isUndefined(select))
                    select = 'all';

                if (select === 'all')
                {
                    $(targetSelector).each(function(checkIndex){

                        $checkbox = $(this);

                        if(!$checkbox.prop("disabled")) {

                            var prevCheckValue = $checkbox.prop("checked");
                            var newCheckValue = true;

                            $checkbox.prop("checked", newCheckValue);

                            if (prevCheckValue != newCheckValue)
                                $checkbox.change();
                        }
                    });

                    $selectInput.data('batch-checkbox-select', 'none');
                }
                else
                {
                    $(targetSelector).each(function(checkIndex){

                        $checkbox = $(this);

                        if(!$checkbox.prop("disabled")) {

                            var prevCheckValue = $checkbox.prop("checked");
                            var newCheckValue = false;

                            $checkbox.prop("checked", newCheckValue);

                            if (prevCheckValue != newCheckValue)
                                $checkbox.change();
                        }
                    });

                    $selectInput.data('batch-checkbox-select', 'all');
                }

                if (settings.onProcessing)
                {
                    settings.onProcessing(false);
                }

            }, preDelay);
        };

        $(this).off('click').on('click', selectClick);

        return {
            selectClick: selectClick,
            getSelectedIds : function()
            {
                var targetSelector = getCheckboxSelector($input);

                var ids = [];

                $(targetSelector).each(function(checkIndex){
                    if ($(this).prop("checked"))
                    {
                        ids.push($(this).attr("value"));
                    }
                });

                return $.unique(ids);
            }
        };
    };

}( jQuery ));