var Timetable = Timetable || {};

Timetable.CreateTimetableComposer = (function (LE, App, _, $, document, Handlebars) {

    /**
     *
     * Step 1. Select tutor from payload returned filtered by current school_id
     * Step 2. Add new tutor time block
     * Step 3. Add new timeblock for that tutor
     * Step 4. Add new timeblock for that tutor, clone of the previous time block
     * Step 5. On select of instrument, filter students with only that instrument
     * Step 6. Set day for time block?
     * END. Parse data to send up to server, similar to that of school PDF function
     *
     * @param options
     * @returns {{}}
     */
    var instance = function(options) {

        // Initial school
        // Tutor row from dropdown
        // Add time block student (start from 12:00 and then duplicate previous row but in 20 minute sections)
        // Student dropdown in time block filter based on instrument selected?

        // TODO: How would we handle validation error?

        var responseJson,
            tutors = [],
            students = [],
            instruments = [],
            $container = $('.panel-body');

        var init = function()
        {
            instruments = JSON.parse(options.instruments);

            getAccountData();
        };

        var parseExistingEntries = function() {
            // Check if edit
            // Ajax get entries
            // Then parse json returned

            $.ajax({
                url: options.get_url,
                dataType: 'json',
                success: function(response) {
                    responseJson = response;
                    //console.log(responseJson);

                    // foreach object in responseJson
                    // append tutor and day row with preselected
                    // then foreach object in entries,
                    // append entry row with preselected students, info filled out (with id paramater)

                    _.each(responseJson, function(element, index, list) {

                        generateTutorBlock(parseInt(element.tutor), element.day);

                        var $ele = $('[data-day="'+element.day+'"][data-tutor="'+element.tutor+'"]');

                        _.each(element.entries, function(entry, entryIndex) {

                            generateTimeBlockForTutor(
                                $ele.find('button[data-add-tutortime]'),
                                entry.id,
                                entry
                            );

                            // Then select the students
                            var eStudents = [];
                            _.each(entry.students, function(e) {
                                eStudents.push(''+e);
                            });

                            $('[data-timeentry][data-entry-id="'+entry.id+'"]')
                                .find('td[data-students] select')
                                .val(eStudents)
                                .trigger('change');
                        });

                        $ele.find('select[name="tutor"]').val(element.tutor).trigger('change');
                        $ele.find('select[name="day"]').val(element.day).trigger('change');
                    });
                }
            })

        };

        var getAccountData = function(){
            $.ajax({
                url: options.payload_url,
                dataType: 'json',
                success: function(response) {
                    responseJson = response;

                    // Parse tutors
                    var tutorsInSchool = _.filter(responseJson.tutors, function (obj) {
                        return $.inArray(options.school_id, obj.school_ids) !== -1;
                    });

                    _.each(tutorsInSchool, function (element, index, list) {
                        tutors.push({
                            id: element.id,
                            name: element.text,
                        })
                    });

                    var studentsInSchool = _.findWhere(response.students, {_id: options.school_id});

                    if (studentsInSchool !== undefined) {
                        _.each(studentsInSchool.children, function (element, index, list) {
                            students.push({
                                id: element.id,
                                name: element.text,
                                text: element.text + ' (' + element.year + ' ' + element.class + ')',
                                class: element.class,
                                year: element.year,
                                instruments: element.instruments
                            })
                        });
                    }
                },
                complete: function(data) {
                    if(options.updating) {
                        parseExistingEntries();
                    }
                }
            });
        };

        // On click of tutor_add, add tutor block
        var generateTutorBlock = function(id, dayInt) {
            var html,
                template = LE.templates['timetable_tutor_day'];

            html = template({
                tutorId: id,
                day: dayInt,
                tutors: tutors
            });

            $('.panel-body')
                .find('#output')
                .append(html);

            initSelect2();
        };

        var initSelect2 = function()
        {
            try {
                $('select.select2-hidden-accessible').select2('destroy');
            } catch (e) {

            } finally {
                $('select').select2();
            }
        };

        // On click of tutor_timeblock_add, add new time entry
        // Clone previous row data but add 20 minutes
        var generateTimeBlockForTutor = function($element, id, data) {
            var html,
                template = LE.templates['timetable_tutor_time'];

            var $tableBody = $element
                .closest('div[data-tutor-block]')
                .find('.row[data-tutor-timerow]')
                .find('table tbody');

            if(id > 0) {
                html = template({
                    start: data.start,
                    end: data.end,
                    room: data.room,
                    instrument: data.instrument,
                    instruments: instruments,
                    students: students,
                    entryId: id
                });

                $tableBody
                    .append(html);

                $tableBody
                    .find('[data-entry-id="'+id+'"]')
                    .find('td[data-instrument] select')
                    .val(data.instrument);

            } else {

                if ($tableBody.find('tr').length == 0) {
                    // Populate instrument select
                    html = template({
                        start: '12:00',
                        end: '12:20',
                        room: '',
                        instrument: '',
                        instruments: instruments,
                        students: students,
                        entryId: id
                    });

                    $tableBody
                        .append(html);
                } else {
                    $('select').select2('destroy');

                    var $row = $tableBody.find('tr:last');
                    var $a = $($row).clone();

                    $a.data('entry-id', id);
                    $a.attr('data-entry-id', id);

                    var start = $row.find('td[data-start] input').val();
                    var end = $row.find('td[data-end] input').val();
                    var instrument = $row.find('td[data-instrument] select').val();
                    // var students = $row.find('td[data-students] select').val();

                    $a.find('td[data-start] input')
                        .val(
                            moment(start, 'HH:mm')
                                .add(20, 'minutes')
                                .format('HH:mm')
                        );

                    $a.find('td[data-end] input')
                        .val(
                            moment(end, 'HH:mm')
                                .add(20, 'minutes')
                                .format('HH:mm')
                        );

                    $a.find('td[data-instrument] select').val(instrument);
                    // $a.find('td[data-students] select').val(students);

                    // Update start and end time by 20 minutes
                    $a.insertAfter($row);
                }
            }

            initSelect2();

            sortable('.o-sortable', 'reload');

            // On sortable update
            // TODO: This only works in the same container (tutor day)
            $('.o-sortable').off('sortupdate').on('sortupdate', function(e) {
                // Get current element
                var $this = $(e.detail.item);
                // Get current element times
                var times = {
                    start: $this.find('td[data-start] input').val(),
                    end: $this.find('td[data-end] input').val(),
                };

                // Create newTimes variable
                var $newTimes;

                var filtered;
                var $filtered;

                // If we moved the element up (smaller index)
                if(e.detail.destination.index < e.detail.origin.index) {
                    // console.log('moved up');
                    // get elements greater than / equal to new index
                    // get elements less than old index
                    filtered = e.detail.destination.items.filter(function(element, index) {
                        return index > e.detail.destination.index && index <= e.detail.origin.index;
                    });

                    // jQuery object
                    $filtered = $(filtered);
                    // foreach $filtered
                    $filtered.each(function(index) {
                        var $that = $(this);
                        var $next;

                        // If the element is first index (the one pushed down) then set the element times to the current filtered
                        if(index === 0) {
                            $newTimes = {
                                start: $that.find('td[data-start] input').val(),
                                end: $that.find('td[data-end] input').val()
                            }
                        }

                        // If the next index is the length,
                        // then the next times will be the original element times we moved
                        if(index+1 === $filtered.length) {
                            $next = {
                                start: times.start,
                                end: times.end
                            }
                        } else {
                            // Else shift the times to the next in the array
                            $next = {
                                start: $that.next().find('td[data-start] input').val(),
                                end: $that.next().find('td[data-end] input').val()
                            };
                        }
                        // Set the current $(filtered) element new times
                        $that.find('td[data-start] input').val($next.start);
                        $that.find('td[data-end] input').val($next.end);
                    });
                }

                // If we moved the element down (larger index)
                if(e.detail.destination.index > e.detail.origin.index) {
                    // console.log('moved down');
                    // get elements less than / equal to new index
                    // get elements greater than old index
                    filtered = e.detail.destination.items.filter(function(element, index) {
                        return index < e.detail.destination.index && index >= e.detail.origin.index;
                    });

                    $filtered = $(filtered);

                    // jQuery object
                    $filtered = $(filtered);
                    // foreach $filtered
                    var $prevTimes = [];

                    $filtered.each(function(index) {
                        var $that = $(this);
                        var $prev;
                        // Set the previous times
                        $prevTimes[index] = {
                            start: $that.find('td[data-start] input').val(),
                            end: $that.find('td[data-end] input').val()
                        };

                        // If first element pushed up, set current filtered to element
                        if(index === 0) {
                            $prev = {
                              start: times.start,
                              end: times.end,
                            }
                        } else {
                            // The times are the previous index times
                            $prev = $prevTimes[index-1];
                        }

                        if(index + 1 === $filtered.length) {
                            $newTimes = {
                                start: $that.find('td[data-start] input').val(),
                                end: $that.find('td[data-end] input').val()
                            };
                        }
                        // Set the current $(filtered) element new times
                        $that.find('td[data-start] input').val($prev.start);
                        $that.find('td[data-end] input').val($prev.end);
                    });

                }

                // Set the new times for the current element
                $this.find('td[data-start] input').val($newTimes.start);
                $this.find('td[data-end] input').val($newTimes.end);
            });
        };

        // Filter students by selected instrument
        var filterStudentsOnInstrument = function($element) {

            // $element is the select element
            // Get nearest row, then get student dropdown, then filter by instrument id
            var selectedInstrument = parseInt($element.val());

            var $studentSelect = $element
                .closest('tr[data-timeentry]')
                .find('td[data-students] select');

            var _students = App.Common.deepClone(students);

            var filteredStudents = _.filter(_students, function(obj) {
                return $.inArray(selectedInstrument, obj.instruments) !== -1;
            });

            $studentSelect.select2('destroy').empty()
                .select2({data: filteredStudents});
        };

        // Save data in similar format as returned by school generatePDF
        var submit = function() {
            var collection = [];

            // 1. Get tutor blocks (Tutor name, Day)
            var $tutorBlocks = $('div[data-tutor-block]');

            // 2. Each $tutorBlocks, we get the data-timeentry collection and store into collection
            _.each($tutorBlocks, function(element, index, list) {
                var $element = $(element);

                var object = {
                    tutor: parseInt($element.find('select[name=tutor]').val()),
                    day: parseInt($element.find('select[name=day]').val()),
                    entries: []
                };

                var $timeEntries = $(element).find('tr[data-timeentry]');
                _.each($timeEntries, function(element1, index1, list1) {
                    var $entry = $(element1);

                    var entryObj = {
                        students: $entry.find('td[data-students] select').val(),
                        start: $entry.find('td[data-start] input').val(),
                        end: $entry.find('td[data-end] input').val(),
                        room: $entry.find('td[data-room] input').val(),
                        instrument: parseInt($entry.find('td[data-instrument] select').val()),
                        id: parseInt($entry.data('entry-id'))
                    };

                    object.entries.push(entryObj);
                });

                collection.push(object);
            });

            $container.find('form input[name=timetable_data]').val(JSON.stringify(collection));
            $container.find('form').submit();
        };

        // Register handles etc
        $('button[data-add-tutorday]').on('click', function(e) {
            generateTutorBlock(0, 0);
        });

        $container.on('click', 'button[data-add-tutortime]', function(e) {
            var $this = $(this);
            generateTimeBlockForTutor($this, 0);
        });

        $container.on('change', 'td[data-instrument] select', function(e) {
            filterStudentsOnInstrument($(this));
        });

        $container.on('click', 'td[data-remove] .btn', function(e) {
            $(this)
                .closest('tr[data-timeentry]')
                .remove();
        });

        $container.on('click', 'input[type=submit]', function(e) {
            e.preventDefault();
            submit();
        });

        init();

        sortable('.o-sortable', {
            forcePlaceholderSize: true,
            //acceptFrom: '.o-sortable'
        });

        return {

        }
    };

    var create = function(options)
    {
        return new instance(options);
    };

    return {
        create: create
    };

})(LE, App, _, jQuery, document, Handlebars);

if ( typeof module === "object" && typeof module.exports === "object" ) {
    module.exports = Timetable.CreateTimetableComposer;
}