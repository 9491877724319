// Logic to call JQuery Datables plugin that uses separate search and/or limit controls

(function ( _, $ ) {

    $.fn.DataTableControls = function( options ) {


        // set page length based on current page length selector (if id is passed in)
        var startPageLength = 25;

        var $pageLengthSelect;

        if (options.pageLengthId)
        {
            $pageLengthSelect = $(options.pageLengthId);

            startPageLength = $pageLengthSelect.val();
        }

        // create datatable

        options.dataTable.pageLength = startPageLength;

        // register datatable events pre initialisation
        if (options.onProcessing)
        {
            $(this).on('processing.dt', function(e, settings, processing){
                options.onProcessing(processing);
            });
        }

        if(_.isUndefined(options.dataTable.stateSave)) {
            options.dataTable.stateSave = true;
        }

        // This is to bang the search value in the searchbox on stateloaded
/*        if(options.search)
        {
            $(this).on( 'stateLoaded.dt', function (e, settings, data) {
                $(options.search).val(data.search.search);
            } );
        }*/

        var dataTableOptions = $.extend({}, options.dataTable);

        dataTableOptions.oLanguage = $.extend({

            sEmptyTable: "No results!"

        }, dataTableOptions.oLanguage);

        var $dataTable = $(this).DataTable(
            dataTableOptions
        );

        // add optional input search field logic
        if (options.search)
        {
            // search can be id or jquery element

            var search = $(options.search);

            if (search)
            {
                $searchEl = null;

                if ($.type(search) === "string")
                    $searchEl = $(search);
                else
                    $searchEl = search;

                // Call table search on press of search icon
                var $searchIcon = $searchEl.parent().find('.input-group-addon');
                $searchIcon.css('cursor', 'pointer');

                $searchIcon.click(function(e)
                {
                    if($searchEl.val().length > 0)
                        $dataTable.search($searchEl.val()).draw();
                });

                // call table search on return key
                $searchEl.keyup(function(e){

                    if (e.keyCode == LE.Keycodes.return) {

                        $dataTable.search($searchEl.val()).draw();

                    }
                });
            }
        }

        // add page length select change handler
        if ($pageLengthSelect)
        {
            $pageLengthSelect.change(function(){

                var newPageLength = $(this).val();

                $dataTable.page.len(newPageLength);

                $dataTable.ajax.reload();

                $dataTable.draw();
            });
        }

        // setup batch controls after drawtable has been initialised
        return $dataTable;
    };

}( _, jQuery ));